import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../constants/api.constants';
import { filter, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ConfigLoaderService {
  private config$: BehaviorSubject<AppConfig> = new BehaviorSubject(null);
  private filename: string;

  constructor(private http: HttpClient, private titleService: Title) {
    this.filename = environment.name ? `assets/env.${environment.name}.json` : 'assets/env.json';

    this.config$.pipe(filter((config) => !!config)).subscribe((config) => {
      this.titleService.setTitle(config.pageTitle);
    });
  }

  load() {
    return this.http
      .get<AppConfig>(this.filename)
      .pipe(tap((config) => this.config$.next(config)))
      .toPromise();
  }

  getConfig(): Observable<AppConfig> {
    return this.config$.asObservable().pipe(
      filter((config) => !!config),
      take(1)
    );
  }
}
