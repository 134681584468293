import { filter } from 'rxjs/operators';

import { Component } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRouteSnapshot, Route } from '@angular/router';
import { AuthService } from './authentication/services/auth.service';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewVersionNotificationComponent } from './pwa/new-version-notification/new-version-notification.component';
import { ACCEPTED_NON_AUTH_ROUTES } from './shared/constants/global.constants';

const VAPID_PUBLIC_KEY = 'BEpY5N_raG8qJMM5zBP-Q34hedFzkM8ELsK6JLhwP7BHaJxq3O44XmCrgB6vf5bvWU8xiLaj-vajaCGS6oDeM-k';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'club-manager';

  isAuthenticated = false;
  onNonAuthenticatedRoutes = true;
  currentArea: string = null;
  onRoot = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private snackBar: MatSnackBar
  ) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(filter((event: VersionEvent) => event.type === 'VERSION_READY')).subscribe(() => {
        this.snackBar.openFromComponent(NewVersionNotificationComponent, {
          panelClass: 'mat-mdc-snack-bar-container--success',
          verticalPosition: 'top',
        });
      });
    }

    this.authService.authenticatedStateChange.subscribe((authenticated) => {
      this.isAuthenticated = authenticated;

      if (authenticated && this.swPush.isEnabled) {
        this.checkNotificationsSubscription();
      }
    });

    this.router.events.pipe(filter((e) => e instanceof RoutesRecognized)).subscribe((event: RoutesRecognized) => {
      this.onRoot = this.getLastRouteChildCount(event.state.root) === 1;

      const activatedPath = this.getLastRouteChildConfig(event.state.root)?.path;
      this.onNonAuthenticatedRoutes = !this.onRoot && ACCEPTED_NON_AUTH_ROUTES.some((acceptedUrl) => acceptedUrl === activatedPath);
    });
  }

  private checkNotificationsSubscription(isRetry = false) {
    this.swPush
      .requestSubscription({
        serverPublicKey: VAPID_PUBLIC_KEY,
      })
      .then((sub) => {
        this.authService.subscribeToPushNotifications(sub).subscribe(() => {});

        // TODO - Redirect user to appropriate page on notification click
        this.swPush.notificationClicks.subscribe((message) => console.log('Notification click', message));
      })
      .catch((err: Error) => {
        if (isRetry) {
          this.snackBar.open(
            'Ocorreu um erro ao ativar as suas notificações. Verifique as permissões para notificações do seu browser para este website',
            'Close',
            {
              panelClass: 'mat-mdc-snack-bar-container--error',
              verticalPosition: 'top',
            }
          );

          return;
        }

        if (err.name === 'InvalidStateError') {
          this.swPush.unsubscribe().then(() => {
            this.checkNotificationsSubscription(true);
          });
        }
      });
  }

  private getLastRouteChildConfig(activatedRouteSnapshot: ActivatedRouteSnapshot): Route {
    return activatedRouteSnapshot.firstChild
      ? this.getLastRouteChildConfig(activatedRouteSnapshot.firstChild)
      : activatedRouteSnapshot.routeConfig;
  }

  private getLastRouteChildCount(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return activatedRouteSnapshot.firstChild ? this.getLastRouteChildCount(activatedRouteSnapshot.firstChild) + 1 : 0;
  }
}
