import { CmEntity, CmEntityApiData } from 'src/app/shared/models/cm-entity.model';

export interface PushSubscriptionObjectApiData extends CmEntityApiData {
  endpoint: string;
  expirationTime: number;
  keys: {
    p256dh: string;
    auth: string;
  };
}

export class PushSubscriptionObject extends CmEntity {
  endpoint: string;
  expirationTime: number;
  keys: {
    p256dh: string;
    auth: string;
  };

  constructor(payload: PushSubscriptionObject | PushSubscriptionObjectApiData) {
    super(payload);

    this.endpoint = payload.endpoint;
    this.expirationTime = payload.expirationTime;
    this.keys = payload.keys;
  }

  toRequestModel(): PushSubscriptionObjectApiData {
    return {
      ...super.toRequestModel(),

      endpoint: this.endpoint,
      expirationTime: this.expirationTime,
      keys: this.keys,
    };
  }
}
